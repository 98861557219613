import { Injectable } from '@angular/core';
import { GenericModalComponent } from '../../modules/modals/components/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class GenericModalService {
  constructor(public dialog: MatDialog) {}

  public showModal(data, modalClosed?: ($event: any) => void) {
    const dialogRef = this.dialog.open(GenericModalComponent, {
      panelClass: ['modal', 'material'],
      position: { top: '10vh' },
      disableClose: data.ignoreBackdropClick || false,
    });
    dialogRef.componentInstance.dataInfo = data;
    dialogRef.componentInstance.modalClosed.subscribe((result) => {
      dialogRef.close('closed');
      if (modalClosed) {
        modalClosed(result);
      }
    });
    dialogRef.afterClosed().subscribe((reason: string) => {
      if (reason !== 'closed') {
        if (modalClosed) {
          if (data.onClose) {
            modalClosed({ status: true, data: { kind: 'onClose' } });
          } else {
            modalClosed({});
          }
        }
      }
    });
  }
}
