<div class="modal-content" id="genericModal">
  <div class="modal-body">
    <div class="row text-center">
      <div class="col-md-12">
        <button [hidden]="dataInfo.hideCloseBtn" type="button" class="close" (click)="hideModal()">
          <span class="sr-only">Close</span>
          <span class="ss-icon-close"></span>
        </button>
        <img *ngIf="dataInfo?.img" src="./assets/images/{{dataInfo.img}}" alt="{{dataInfo.img}}" style="height: 150px;"
          class="mb10">
        <h4 class="modal-title text-center" *ngIf="dataInfo.cssClass || dataInfo.googleIcon">
          <span style="font-size: 70px" class="{{ dataInfo.cssClass }}">{{
            dataInfo.googleIcon || ''
          }}</span>
        </h4>
        <h2 class="modal-title" [ngClass]="{'large-section-title': dataInfo.largeSection}">{{ dataInfo.title }}</h2>
      </div>
    </div>
    <p *ngIf="dataInfo.largeSection" class="large-section-p">{{ dataInfo.message | hideExtension }}</p>
    <div class="row text-center">
      <div class="col-md-12">
        <p *ngIf="!dataInfo.largeSection">{{ dataInfo.message | hideExtension }}</p>
        <p
          *ngIf="!dataInfo.isCopyUrl && dataInfo.auxMessage"
          class="{{ dataInfo.cssAuxClass }}"
          style="display: inline-block"
        >
          {{ dataInfo.auxMessage }}
        </p>
        <span
          *ngIf="dataInfo.auxIcon"
          class="material-icons"
          [ngStyle]="dataInfo.auxIconStyles || {}"
          [tooltip]="dataInfo.auxIconMessage || ''"
          [attr.data-icon]="dataInfo.auxIcon"
        >
        </span>
        <div
          *ngIf="dataInfo.isCopyUrl"
          class="input-group center mt10"
          (click)="copyToClipboard(dataInfo.auxMessage)"
        >
          <input
            data-hj-allow
            type="text"
            class="form-control"
            id="basic-url"
            value="{{ dataInfo.auxMessage }}"
            disabled
          />
          <span class="input-group-addon" id="copyBtn">{{ textCopied ? 'Copied' : 'Copy' }}</span>
        </div>
        <br /><br />
        <button
          class="btn mr10 {{ btn.cssClass }}"
          *ngFor="let btn of dataInfo.btns"
          (click)="getBtnAction(btn)"
        >
          {{ btn.title }}
        </button>
      </div>
    </div>
  </div>
</div>
